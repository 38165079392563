import React from 'react';
import calendar from '../../../assets/images/calendario.svg'
import bell from '../../../assets/images/alertas.svg'
  export default function SingleVaccine ({name , date , reminder}) {
  return (
    <div style={{marginTop:12}}>
      <div>
        <p style={{ font: "var(--fs-14-semibold)", color: "#333"}}>{name}</p>
      </div> 
    
      <div style={{display:'flex' ,flexDirection:'row' , alignItems:'center', marginTop:4}}>  
       
        <div style={{display:'flex' ,flexDirection:'row' , alignItems:'center', textAlign:'center'}}>
          <img src={calendar} alt="icon" />
          <p  style={{font: 'var(--fs-14-medium)', color: '#4F4F4F' , marginLeft: 6} }>{date}</p>
        </div>
        
        <div style={{display:'flex' ,flexDirection:'row' , alignItems:'center', textAlign:'center' , marginLeft: 26}}>
          <img src={bell} alt="icon" />
          <p style={{font: 'var(--fs-14-medium)', color: '#4F4F4F', marginLeft: 6}}> {reminder || 'Sin recordatorio'}</p> 
        </div>
          
        </div>
    </div>
  );
}

