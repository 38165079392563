import {Container, Row, Col} from "react-bootstrap"
import logo from "../../assets/images/logo.png"
import Paragraph from "../../components/atoms/Paragraphs/Paragraphs";
import styles from "./SplashScreen.module.css"
export default function SplashScreen ({notFound}) {
  const { SplashScreen, SplashScreenImgContainer } = styles;
  return (
    <Container fluid>
      <Row className={SplashScreen}>
        <Col xs={12} className={SplashScreenImgContainer}>
          <img src={logo} alt="Findpet" />
        </Col>
        {notFound && (
          <Col xs={12} className="mt-5">
            <Paragraph
              text="No se encontró el animalito"
              font="var(--fs-20-semibold)"
              color="var(--black)"
              withIcon={false}
              textAlign="center"
            />
          </Col>
        )}
      </Row>
    </Container>
  );
}