import { Col } from "react-bootstrap";
import Paragraph from "../../atoms/Paragraphs/Paragraphs";
import Title from "../../atoms/Titles/Title";

export default function ProfileTitle ({titleText, subtitleText, titleStyle, subtitleStyle, ruf}) {
  return (
    <Col xs={12} className="mt-3">
      <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
        <Title
          type="heading1"
          text={titleText}
          font={titleStyle}
          marginBottom={8}
          marginTop={24}
        />
        <Title 
          text={`RUF ${ruf}`} 
          type="heading2"
          font={"var(--fs-24-semibold)"}
          marginBottom={8}
          marginTop={24} 
        />
      </div>
      <Paragraph font={subtitleStyle} text={subtitleText} color="#727272" />
    </Col>
  );
}