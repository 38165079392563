import { Col } from "react-bootstrap";
import Button from "../../atoms/Buttons/Button"

import envelopeIcon from "../../../assets/images/envelopeIcon.svg"
import phoneIcon from "../../../assets/images/phoneIcon.svg"
import whatsappIcon from "../../../assets/images/whatsappIcon.svg"

export default function SocialIcons({ data }) {
  return (
    <Col className="d-flex flex-wrap align-items-center justify-content-end">
      <Button
        withIcon={true}
        buttonStyle="socialBtn"
        iconImage={envelopeIcon}
        type="link"
        linkTo={`mailto:${data.user.email}`}
      />
      <Button
        withIcon={true}
        buttonStyle="socialBtn"
        iconImage={phoneIcon}
        type="link"
        linkTo={`tel:+${data.user.phone.replace("+", "")}`}
      />
      <Button
        withIcon={true}
        buttonStyle="socialBtn"
        iconImage={whatsappIcon}
        type="link"
        linkTo={`https://api.whatsapp.com/send?phone=${data.user.phone.replace(
          "+",
          ""
        )}`}
      />
    </Col>
  );
}