import { Col, Row } from "react-bootstrap";
import Button from "../../atoms/Buttons/Button";
import phoneIcon from "../../../assets/images/phoneIcon.svg";
import emailIcon from "../../../assets/images/emailIcon.svg";
import whatsappIconWhite from "../../../assets/images/whatsappIcon-white.svg";
import styles from "./ContactButtons.module.css";
import { useState } from "react";


export default function ContactButtons({ data }) {

  const { ContactButtonsDesktop } = styles;


  return (
    <Col xs={12} className={ContactButtonsDesktop}>
      <Row>
        <Col xs={6}>
          <Button
            withIcon={true}
            text="Llamar"
            iconImage={phoneIcon}
            buttonStyle="contactDesktopButton"
            type="link"
            onPress={() => {}}
            linkTo={`tel:${data.user.phone}`}
          />
        </Col>
        <Col xs={6}>
          <Button
            withIcon={true}
            text="Enviar email"
            iconImage={emailIcon}
            buttonStyle="contactDesktopButton"
            type="link"
            linkTo={`tel:${data.user.phone}`}
          />
        </Col>
        <Col xs={12} style={{marginTop: '16px'}}>
          <Button
            withIcon={true}
            text="Enviar whatsapp"
            iconImage={whatsappIconWhite}
            buttonStyle="whatsappBtnDesktop"
            type="link"
            linkTo={`https://api.whatsapp.com/send?phone=${data.user.phone.replace(
              "+",
              ""
            )}`}
          />
        </Col>
      </Row>
    </Col>
  );
}
