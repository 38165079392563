import {Col} from "react-bootstrap"
import Icon from "../../atoms/Icons/Icon";
import Paragraph from "../../atoms/Paragraphs/Paragraphs";
import Title from "../../atoms/Titles/Title";
export default function DataWithIcon ({alignItems, justifyContent, icon, iconStyle, text_primary, text_secondary}) {
  return (
    <Col className="d-flex flex-wrap align-items-center" style={{justifyContent: justifyContent, alignItems: alignItems}}>
      <div>
        <Icon image={icon} style={iconStyle} />
      </div>
      <div style={{marginLeft: 12}}>
        <Title
          type="heading3"
          text={text_primary.text}
          font={text_primary.font}
          marginBottom={2}
        />
        {text_secondary && <Paragraph text={text_secondary.text} font={text_secondary.font} color={text_secondary.color} />}
      </div>
    </Col>
  );
}