import axios from "axios";
import React from "react";
import { Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {ContactButtons} from "../../components/molecules";
import {InfoContainer, Loader} from "../../components/templates";
import SplashScreen from "../SplashScreen/SplashScreen";

export default function Profile() {
  // Params from url
  const {pet_identification} = useParams()

  // Data state
  const [data, setData] = React.useState()
  const [isLoading, setIsLoading] = React.useState(true)
  const [notFound, setNotFound] = React.useState(false)
  
  // On mount Component
  React.useEffect(() => {
   axios.get(`${process.env.REACT_APP_API_URL}public/pet/${pet_identification}`)
      .then((res) => {
        setTimeout(() => {
          setIsLoading(false)
        },2000)
        setData(res.data);
      })
      .catch((err) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
        setNotFound(true)
      });
  }, [pet_identification]);

  // Jsx Return
  return !isLoading ? (
    !notFound ? (
      <Container fluid>
        <Row>
          {/* ----------------------------- Info Container ----------------------------- */}
          <InfoContainer data={data} />
          <ContactButtons data={data} />
        </Row>
      </Container>
    ) : (
      <SplashScreen notFound={true} />
    )
  ) : (
    <Loader />
  ); 
}