import { Col, Row } from "react-bootstrap"
import Button from "../../atoms/Buttons/Button"
import phoneIcon from "../../../assets/images/phoneIcon.svg"
import whatsappIconWhite from "../../../assets/images/whatsappIcon-white.svg";
import styles from "./ContactButtons.module.css"
import ContactModal from "../../organisms/ContactModal/ContactModal";
import { useState } from "react";


export default function ContactButtons ({data}) {

  const { ContactButtonsContainer, ContactButtons } = styles;

  const [showPhoneModal, setShowPhoneModal] = useState(false);
  const [showWppModal, setShowWppModal] = useState(false);


  return (
    <Col xs={12} className={ContactButtonsContainer}>
      <Col xs={12} className={ContactButtons}>
        <Button
          withIcon={true}
          text="Llamar"
          iconImage={phoneIcon}
          buttonStyle="phone"
          type="button"
          onPress={() => setShowPhoneModal(true)}
        />
        <Button
          withIcon={true}
          text="Enviar whatsapp"
          iconImage={whatsappIconWhite}
          buttonStyle="whatsappBtn"
          type="button"
          onPress={() => {setShowWppModal(true)}}
        />
        <ContactModal 
          data={data} 
          showWppModal={showWppModal} 
          setShowWppModal={setShowWppModal}
          showPhoneModal={showPhoneModal}
          setShowPhoneModal={setShowPhoneModal}
        />
      </Col>
    </Col>
  );
}