import React from 'react';
import Title from '../../atoms/Titles/Title';
import cerrar from '../../../assets/images/cerrar.svg';
import wpp from '../../../assets/images/wppIconColor.svg';
import phone from "../../../assets/images/phoneIcon.svg";
import Button from '../../atoms/Buttons/Button';

export default function ContactModal({ data, showWppModal, showPhoneModal, setShowWppModal, setShowPhoneModal, mobile = true }) {
    
  return (
    (showPhoneModal || showWppModal) &&
      <div className="modal">
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'normal', padding: 20}}>
          <img 
            src={cerrar} 
            alt="icon" 
            style={{paddingRight:8}} 
            onClick={() => showWppModal ? setShowWppModal(false) : setShowPhoneModal(false)}
          />
          <Title
              type="heading2"
              text={`Contactar por ${showWppModal ? "Whatsapp" : "Teléfono"}`}
              font={"var(--fs-20-bold)"}
              marginBottom={0}
              />
        </div>
        <div style={{paddingLeft:20, paddingRight:20}}>
          <p>
            Contactá a las personas responsables de la mascota por {showWppModal ? "Whatsapp" : showPhoneModal ? "Teléfono" : ""}.
          </p>
        </div>
        <div style={{ marginTop:24 }}>
          {data.users.length > 0 ? data.users.map((user, i) => (
            <>
              <div key={i} style={{ display: 'flex', flexDirection: 'row' ,  alignItems:'center', justifyContent:'space-between',  marginTop:24 ,marginBottom:10, paddingLeft:20, paddingRight:20 }}>
                <p>{user.name}</p>
                <Button
                  withIcon={true}
                  buttonStyle="socialBtn"
                  iconImage={showWppModal ? wpp : phone}
                  type="link"
                  linkTo={showWppModal ? `https://api.whatsapp.com/send?phone=${data.user.phone.replace(
                    "+",
                    ""
                  )}` : `tel:${user.phone}`}
                />
              </div>
            </>
          )):
          <>
          <div style={{ display: 'flex', flexDirection: 'row' ,  alignItems:'center', justifyContent:'space-between',  marginTop:24 ,marginBottom:10 , paddingLeft:20, paddingRight:20 }}>
            <p>{data.user.name}</p>
            <Button
              withIcon={true}
              buttonStyle="socialBtn"
              iconImage={showWppModal ? wpp : phone}
              type="link"
              linkTo={showWppModal ? `https://api.whatsapp.com/send?phone=${data.user.phone.replace(
                "+",
                ""
              )}` : `tel:${data.user.phone}`}
            />
          </div>
        </>
          }
        </div>
      </div>
  );
}


