import {Row, Col, Image} from "react-bootstrap"
import styles from "./ProfileImage.module.css"
export default function ProfileImage({image, name, imageStyles}){
  return (
    
    <Col xs={12} className={styles[imageStyles]}>
      <img src={image} alt={name} />
    </Col>

  );
}