
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ProfileMobile, ProfileDesktop } from "./pages/Profile";
import SplashScreen from "./pages/SplashScreen/SplashScreen";
import { useMediaQuery } from "react-responsive";

function App() {
  // Responsive Query
  const isDesktopOrTablet = useMediaQuery({
    query: "(min-width: 1024px)",
  });
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<SplashScreen />} />
        <Route
          exact
          path="/:pet_identification"
          element={isDesktopOrTablet ? <ProfileDesktop /> : <ProfileMobile />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
