import { Col } from "react-bootstrap";
import DataWithIcon from "../../molecules/DataWithIcon/DataWithIcon";
import styles from "./BasicInfo.module.css";


export default function BasicInfo({ data, getIconUrl }) {

  const {BasicInfoContainer} = styles;

  return (
    <Col xs={12} className={BasicInfoContainer}>
      <Col
        xs={12}
        className="d-flex flex-wrap align-items-center justify-content-between"
      >
        <DataWithIcon
          icon={getIconUrl(data.gender.id)}
          iconStyle={{ width: 40, height: 40 }}
          text_primary={{
            text: data.gender.name,
            font: "var(--fs-14-semibold)",
          }}
          text_secondary={{
            text: "Sexo",
            font: "var(--fs-14-medium)",
            color: "#727272",
          }}
        />
        <DataWithIcon
          icon={getIconUrl()}
          iconStyle={{ width: 40, height: 40 }}
          text_primary={{
            text: data.age,
            font: "var(--fs-14-semibold)",
          }}
          text_secondary={{
            text: "Edad",
            font: "var(--fs-14-medium)",
            color: "#727272",
          }}
        />
      </Col>
    </Col>
  );
}