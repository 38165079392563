export default function Paragraph ({text, font, color, withIcon, icon, textAlign="left"}) {
  const style = {
    font: font,
    color: color,
    textAlign: textAlign,
    fontFamily: "Raleway"
  }

  const ParagraphWithIcon = () => (
    <div className="d-flex flex-wrap align-items-center text-align-right justify-content-end">
      <img src={icon} alt="icon" />
      <p style={style}>
        {text}
      </p>
    </div>
  )

  const ParagraphNormal = () => (
    <p style={style}>
      {text}
    </p>
  )
  return withIcon ? <ParagraphWithIcon /> : <ParagraphNormal />
}