import { Col } from "react-bootstrap";
// Icons
import birthdayIcon from "../../../assets/images/birthdayIcon.png";
import femaleGenderIcon from "../../../assets/images/femaleGenderIcon.png";
import maleGenderIcon from "../../../assets/images/maleGenderIcon.png";
import placeholderImg from "../../../assets/images/placeholder-image.jpg";
import unknownGenderIcon from "../../../assets/images/unknownGenderIcon.png";
import BasicInfo from "../../organisms/BasicInfo/BasicInfo";
import ContactInfo from "../../organisms/ContactInfo/ContactInfo";
import ListFeatures from "../../organisms/ListFeatures/ListFeatures";
import ProfileImage from "../../organisms/ProfileImage/ProfileImage";
import ProfileTitle from "../../organisms/ProfileTitle/ProfileTitle";
import Vaccines from "../../organisms/Vaccines/Vaccines";
import styles from "./InfoContainer.module.css";
import PetInfo from "../../organisms/PetInfo/PetInfo";

// JSX return
export default function InfoContainer ({data}) {
  const {infoContainer} = styles
  const getIconUrl = (genderId) => {
    switch(genderId){
      case 1:
        return maleGenderIcon
      case 2:
        return femaleGenderIcon
      case 3:
        return unknownGenderIcon
      default:
        return birthdayIcon
    }
  }
  return (
    <Col xs={12} className={infoContainer}>
      <ProfileImage
        image={
          data.images.length !== 0
            ? `${process.env.REACT_APP_PUBLIC_URL + data.images[0].path}`
            : placeholderImg
        }
        name={data.name}
        imageStyles={"profile-image"}
      />
      {/* -------------------------- Nombre de la mascota -------------------------- */}
      <ProfileTitle
        titleText={data.name}
        subtitleText={data.breed.name}
        titleStyle={"var(--fs-24-bold)"}
        subtitleStyle={"var(--fs-14-medium"}
        ruf={data?.pet_identification}
      />
      {/* ---------------------- Iconos de informacion basica ---------------------- */}
      <BasicInfo data={data} getIconUrl={getIconUrl} />
      {/* ----------------------- Listado de caracteristicas ----------------------- */}
      <ListFeatures data={data} />
      {/* ----------------------- Informacion adicional  ----------------------- */}
      <PetInfo data={data}/>
      {data?.vet_records?.length > 0 && <Vaccines data={data}/>}
      
      {/* ------------------------- Informacion de contacto ------------------------ */}
      <ContactInfo data={data} />
    </Col>
  );
}