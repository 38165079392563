import { Col, Row } from "react-bootstrap";
import Paragraph from "../../atoms/Paragraphs/Paragraphs";
import Title from "../../atoms/Titles/Title";
import check from '../../../assets/images/check.svg'
import cerrar from '../../../assets/images/cerrar.svg'
export default function PetInfo(data) {
  return (
    <>
      <div style={{marginTop:16}}>

    <div className="d-flex flex-wrap align-items-center text-align-center justify-content-start">
      <img src={data.data.is_castrated ? check : cerrar} alt="icon" />
      <p style={{
        font:"var(--fs-14-semibold)",
        color: "#333",
        marginLeft:10
        // textAlign:"center"
      }}>
        {data.data.is_castrated ? 'Está castrado' : 'No está castrado'}
      </p>
    </div>
    <div className="d-flex flex-wrap align-items-center text-align-center justify-content-start">
      <p style={{ font:"var(--fs-14-medium)", color: "#727272", marginLeft:34, marginTop:4
      // textAlign:"center"
      }}>
        {data.data.is_castrated ? `${data.data.name} se encuentra castrado.` : `${data.data.name} no se encuentra castrado.`}
      </p>
    </div>
      
    </div>

    <div style={{marginTop:16}}>

    <div className="d-flex flex-wrap align-items-center text-align-center justify-content-start">
      <img src={data.data.treatments ? check : cerrar} alt="icon" />
      <p style={{
        font:"var(--fs-14-semibold)",
        color: "#333",
        marginLeft:10,
        // textAlign:"center"
          }}>{data.data.treatments ? 'Posee condición medica' : 'No posee condición medica'}</p>
      </div>
      <div className="d-flex flex-wrap align-items-center text-align-center justify-content-start">
      <p style={{ font:"var(--fs-14-medium)", color: "#727272", marginLeft:34, marginTop:4
      // textAlign:"center"
          }}>{data.data.treatments ? `${data.data.treatments}` : `${data.data.name} no registra ninguna condición médica.`}
          </p>
    </div>
    </div>
    </>
  )
} 
// treatments
