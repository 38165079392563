import Icon from "../Icons/Icon";
import styles from "./Button.module.css"
export default function Button({
  withIcon,
  iconImage,
  text,
  onPress,
  buttonStyle,
  size,
  type,
  linkTo,
}) {
  switch (type) {
    case "link":
      return (
        <a href={linkTo} className={styles[buttonStyle]}>
          {withIcon && (
            <Icon image={iconImage} style={{ filter: "brightness(100%)" }} />
          )}
          {text}
        </a>
      );
    case "button":
      return (
        <div onClick={onPress} className={styles[buttonStyle]}>
          {withIcon && (
            <Icon image={iconImage} style={{ filter: "brightness(100%)" }} />
          )}
          {text}
        </div>
      );
    default:
      return (
        <div onClick={onPress} className={styles[buttonStyle]}>
          {withIcon && (
            <Icon image={iconImage} style={{ filter: "brightness(100%)" }} />
          )}
          {text}
        </div>
      );
  }
}