import { Col } from "react-bootstrap";
import Paragraph from "../../atoms/Paragraphs/Paragraphs";
import Title from "../../atoms/Titles/Title";
import Feature from "../../molecules/Feature/Feature";

export default function ListFeatures ({data}) {

  return (
    <>
      <Col xs={12} className="mt-4 mb-2">
        <Title
          type="heading2"
          text={"Características"}
          font={"var(--fs-20-bold)"}
          marginBottom={8}
        />
        <Paragraph
          text={data?.comments || "-"}
          font={"var(--fs-14-medium"}
          color="#333"
        />
      </Col>
      <Feature
        label={{
          text: "Tamaño",
          font: "var(--fs-14-medium)",
          color: "#727272",
        }}
        value={{
          text: data?.size?.name || "-",
          font: "var(--fs-14-semibold)",
          color: "#333333",
        }}
        singleValue={true}
      />
      <Feature
        label={{
          text: "Tipo de pelo",
          font: "var(--fs-14-medium)",
          color: "#727272",
        }}
        value={{
          text: data?.fur_type?.name || "-",
          font: "var(--fs-14-semibold)",
          color: "#333333",
        }}
        singleValue={true}
      />
      <Feature
        label={{
          text: "Trama del pelaje",
          font: "var(--fs-14-medium)",
          color: "#727272",
        }}
        value={{
          text: data?.coat_type?.name || "-",
          font: "var(--fs-14-semibold)",
          color: "#333333",
        }}
        singleValue={true}
      />
      <Feature
        label={{
          text: "Colores",
          font: "var(--fs-14-medium)",
          color: "#727272",
        }}
        value={{
          font: "var(--fs-14-semibold)",
          color: "#333333",
        }}
        data={data?.colors}
        multipleValue={true}
      />
    </>
  );
}