
export default function Title ({size, font, color, type, text, marginBottom=0, marginTop=0}) {
  const style = { 
    font: font,
    marginBottom: marginBottom,
    marginTop: marginTop
  };
  const Heading1 = () => (
    <h1 style={style}>{text}</h1>
  )
  const Heading2 = () => (
    <h2 style={style}>{text}</h2>
  )
  const Heading3 = () => (
    <h3 style={style}>{text}</h3>
  )
  const Heading4 = () => (
    <h4 style={style}>{text}</h4>
  )
  const Heading5 = () => (
    <h5 style={style}>{text}</h5>
  )
  const Heading6 = () => (
    <h6 style={style}>{text}</h6>
  )
  switch(type){
    case "h1":
      return <Heading1 />
    case "h2":
      return <Heading2 />
    case "h3":
      return <Heading3 />
    case "h4":
      return <Heading4 />
    case "h5":
      return <Heading5 />
    case "h6":
      return <Heading6 />
    default:
      return <Heading1 />
    }
}