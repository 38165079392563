
import Title from "../../atoms/Titles/Title";
import SingleVaccine from '../../molecules/SingleVaccine/SingleVaccine';



export default function Vaccines (data) {
  return (
    <div style={{marginTop:32}}>
      <Title
          type="heading2"
          text={"Vacunas y tratamientos"}
          font={"var(--fs-20-bold)"}
          marginBottom={16}
      />
  
        {data && 
          data?.data.vet_records?.map((vaccine,i) => (
            <SingleVaccine
              key={i}
              name={vaccine.name}
              date={vaccine.application_date}
              reminder={vaccine.reminder_date}
            />
          ))
        }
    </div>
  ) ;
} 