import {Row, Col} from "react-bootstrap"
import Title from "../../atoms/Titles/Title";
import BasicInfo from "../../organisms/BasicInfo/BasicInfo";
import ContactInfo from "../../organisms/ContactInfo/ContactInfo";
import ListFeatures from "../../organisms/ListFeatures/ListFeatures";
import ProfileTitle from "../../organisms/ProfileTitle/ProfileTitle";
import styles from "./InfoContainer.module.css"
// Icons
import birthdayIcon from "../../../assets/images/birthdayIcon.png";
import femaleGenderIcon from "../../../assets/images/femaleGenderIcon.png";
import maleGenderIcon from "../../../assets/images/maleGenderIcon.png";
import placeholderImg from "../../../assets/images/placeholder-image.jpg";
import unknownGenderIcon from "../../../assets/images/unknownGenderIcon.png";
import { ContactButtonsDesktop } from "../../molecules";
import PetInfo from '../../organisms/PetInfo/PetInfo';
import Vaccines from '../../organisms/Vaccines/Vaccines';
import ContactModal from '../../organisms/ContactModal/ContactModal';

const InfoContainer = ({data}) => {
  console.log(data)

  const getIconUrl = (genderId) => {
    switch (genderId) {
      case 1:
        return maleGenderIcon;
      case 2:
        return femaleGenderIcon;
      case 3:
        return unknownGenderIcon;
      default:
        return birthdayIcon;
    }
  };
  return (
    <Row>
      <Col xs={12} className="px-5">
        <Row className={styles["infoContainerDesktop"]}>
          <Col className={styles["infoWrapper"]}>
            <div className={styles['scrollContainer']}> 
            <ProfileTitle
              titleText={data.name}
              subtitleText={data.breed.name}
              titleStyle={"var(--fs-32-bold)"}
              subtitleStyle={"var(--fs-16-medium"}
              ruf={data?.pet_identification}
            />
            {/* ---------------------- Iconos de informacion basica ---------------------- */}
              <BasicInfo data={data} getIconUrl={getIconUrl} />
            {/* ----------------------- Listado de caracteristicas ----------------------- */}
              <ListFeatures data={data} />
            {/* ----------------------- Informacion adicional  ----------------------- */}
              <PetInfo  data={data} />
            {/* ----------------------- Vacunas  ----------------------- */}
              {data?.vet_records?.length > 0 && <Vaccines data={data}/>}
              
            {/* ------------------------- Informacion de contacto ------------------------ */}
              <ContactInfo data={data} mobile={false} />
            {/* --------------------------- Botones de contacto -------------------------- */}
              {data.users.length === 0 && <ContactButtonsDesktop data={data} />}
            </div>
          </Col>
          <Col className="p-0 my-0">
            <img
              src={
                data.images.length !== 0
                  ? `${process.env.REACT_APP_PUBLIC_URL + data.images[0].path}`
                  : placeholderImg
              }
              alt={data.name}
              className={styles['imageDesktop']}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default InfoContainer