import {Row, Col} from "react-bootstrap"
import Paragraph from "../../atoms/Paragraphs/Paragraphs";
import starIcon from "../../../assets/images/featured-color.svg"
export default function Feature({label, singleValue, multipleValue, data, value}) {
  return (
    <Col
      xs={12}
      className={`d-flex flex-wrap ${
        singleValue ? "align-items-center" : "align-items-start"
      } justify-content-between my-1`}
    >
      <Col xs={6}>
        <Paragraph text={label.text} font={label.font} color={label.color} />
      </Col>
      {singleValue && (
        <Col xs={6} className="justify-content-end text-align-right">
          <Paragraph
            text={value.text}
            font={value.font}
            color={value.color}
            textAlign="right"
          />
        </Col>
      )}
      {multipleValue && (
        <Col
          xs={6}
          className="align-content-start align-items-start justify-content-end text-align-right"
        >
          {data.map((item) => (
            <Paragraph
              key={item.id}
              text={item.name}
              font={value.font}
              color={value.color}
              withIcon={item.is_dominant ? true : false}
              icon={starIcon}
              textAlign="right"
            />
          ))}
        </Col>
      )}
    </Col>
  );
}