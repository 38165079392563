import axios from "axios";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { ContactButtons } from "../../components/molecules";
import { InfoContainer, InfoContainerDesktop, Loader } from "../../components/templates";
import SplashScreen from "../SplashScreen/SplashScreen";
import { useMediaQuery } from "react-responsive";
import styles from "./Profile.module.css";
import logoHorizontal from "../../assets/images/logo-horizontal.svg";


export default function Profile() {
  // Params from url
  const { pet_identification } = useParams();

  // Data state
  const [data, setData] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);
  const [notFound, setNotFound] = React.useState(false);

  // Responsive Query
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  // On mount Component
  React.useEffect(() => {
    axios
    .get(`${process.env.REACT_APP_API_URL}public/pet/${pet_identification}`)
      .then((res) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
        setData(res.data);
      })
      .catch((err) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
        setNotFound(true);
      });
  }, [pet_identification]);

  // Jsx Return
  return !isLoading ? (
    !notFound ? (
      <Container className={styles["profileDesktop"]} fluid>
        <Container>
          <Row>
            <Col xs={12} className="mb-4">
              <img src={logoHorizontal} alt="Findpet" />
            </Col>
          </Row>
          <InfoContainerDesktop data={data}/>
        </Container>
      </Container>
    ) : (
      <SplashScreen notFound={true} />
    )
  ) : (
    <Loader />
  );
}
