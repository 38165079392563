import { Container, Row, Col } from "react-bootstrap";
import styles from "./Loader.module.css"
import gifLoader from "../../../assets/gif/loader-gif.gif"
export default function Loader () {
  const {LoaderContainer} = styles
  return (
    <Container>
      <Row>
        <Col xs={12} className={LoaderContainer}>
          <img src={gifLoader} />
        </Col>
      </Row>
    </Container>
  )
}