import { Col, Row } from "react-bootstrap";
import Paragraph from "../../atoms/Paragraphs/Paragraphs";
import Title from "../../atoms/Titles/Title";
import ContactButtons from "../../molecules/ContactButtons/ContactButtons";
import SocialIcons from "../../molecules/SocialIcons/SocialIcons";
import styles from "./ContactInfo.module.css";


export default function ContactInfo ({data, mobile=true}) {

  const { contactInfo, contactInfoData } = styles;

  return (
    <>
      <Col xs={12} className={contactInfo}>
      <Title
        type="heading2"
        text={"Contacto"}
        font={"var(--fs-20-bold)"}
        marginBottom={16}
        marginTop={32}
      />
        {data.users.length > 0 ? data.users.map((user, i) => (
        
        <Col xs={12} className={contactInfoData} key={i}>
          <Col xs={6}>
            <Paragraph
              font="var(--fs-14-medium)"
              color="#333"
              text={user.name}
            />
          </Col>
          <Col xs={6}>
            <SocialIcons data={{user: user}} />
          </Col>
        </Col>
     )) 
        
        :  <Col xs={12} className={contactInfoData}>
          <Col xs={6}>
            <Paragraph
              font="var(--fs-14-medium)"
              color="#333"
              text={data.user.name}
            />
          </Col>
          <Col xs={6}>
            <SocialIcons data={data} />
          </Col>
        </Col>
        }
        </Col>
    </>
  );
}